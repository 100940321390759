@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500&family=Open+Sans:wght@400;500&family=Ubuntu&family=Work+Sans:wght@300;400;500&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  font-family: 'Muli';
}

body{
  background-color: black;
}
/* @media screen and (max-width:1200px) {
  html{
    font-size: 15px;

  }
}
@media screen and (max-width:765px) {
  html{
    font-size: 14px;
  }
}
@media screen and (max-width:550px) {
  html{
    font-size: 13px;
  }
}
@media screen and (max-width:430px) {
  html{
    font-size: 12px;
  }
}
@media screen and (max-width:390px) {
  html{
    font-size: 11px;
  }
} */
